<template>
    <div class="customer-search-container">
        <div class="customer-search-container-top">
            <van-nav-bar title="客户池" fixed/>
        </div>
        <div class="customer-search-container-search">
            <van-search placeholder="搜索一下" :disabled="true" show-action @click="picker.search=true">
                <div slot="action" @click="onAdd">新增客户</div>
            </van-search>
        </div>
        <div class="customer-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                        <van-cell :border="true" :label="item.address">
                            <template slot="title">
                                <span class="custom-title">{{item.title}}</span>
                            </template>
                        </van-cell>
                        <template slot="right" v-if="auth.indexOf('/sale/customer-pool/input')">
                            <van-button square type="primary" icon="edit" text="修改" @click="onEdit(item)"/>
                            <van-button square type="danger" icon="delete" text="删除" @click="onDelete(item)"/>
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="customer-search-container-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <!-- 弹出区域 -->
        <div class="customer-search-container-popup">
            <!-- 搜索 -->
            <van-popup v-model="picker.search" position="center" :round="true" :style="{width:'80%',height:'150px'}">
                <!-- 搜索区域 -->
                <van-cell-group>
                    <van-field
                            v-model="option.title"
                            label="客户名称"
                            placeholder="请输入客户名称"
                            clearable
                    />
                    <van-field
                            v-model="option.address"
                            label="客户地址"
                            placeholder="请输入客户地址"
                            clearable
                    />
                    <van-button type="info" icon="passed" size="large" @click="onSearch"
                                style="position:fixed;bottom:0;"
                                text="查询"/>
                </van-cell-group>
            </van-popup>
            <!-- 修改信息 -->
            <van-popup v-model="picker.edit" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <inputCustomer :param="customer" :key="customer.id" @onChange="onEditComplete"
                               @onClose="onAddComplete"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import inputCustomer from './input'

    export default {
        name: "customer-search",
        components: {
            inputCustomer,
        },
        data() {
            return {
                auth: this.$store.state.user.auth || [],
                freshing: false,
                list: [],
                deptList: [],
                userList: [],
                userListCache: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    title: '',
                    address: '',
                },
                picker: {
                    search: false,
                    edit: false,
                },
                customer: {
                    id: 0
                },
                dataCache: [],
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                this.onLoad();
            },
            onSearch() {
                this.option.page = 1;
                this.onLoad();
            },
            onRefresh() {
                this.option.page = 1;
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.customer.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据';
                        } else {
                            that.finishedText = '';
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            onAdd() {
                this.customer.id = 0;
                this.picker.edit = true;
            },
            onAddComplete() {
                this.picker.edit = false;
                this.onSearch();
            },
            onEdit(item) {
                let that = this;
                that.customer = item;
                that.picker.edit = true;
            },
            onEditComplete(data) {
                let that = this;
                that.picker.edit = false;
                that.list.forEach(function (v, k) {
                    if (v.id == data.id) {
                        that.list[k] = data;
                    }
                });
                if (that.dataCache.length > 0) {
                    that.dataCache.forEach(function (v, k) {
                        if (v.id == data.id) {
                            that.dataCache[k].data = data;
                        }
                    })
                }
            },
            onDelete(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '操作不可逆，确认删除吗？'
                }).then(() => {
                    that.$api.customer.delete({id: item.id}).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.onLoad();
                            that.$notify({type: 'primary', message: res.msg});
                        } else {
                            that.$notify({type: 'warning', message: res.msg});
                        }
                    })
                });
            }
        }
    }
</script>

<style scoped>
    .customer-search-container {
        padding-top: 46px;
    }

    .customer-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .customer-search-container-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .customer-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }
</style>
